.Backdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.BackdropOpen {
  display: block;
}

.BackdropClosed {
  display: none;
}
