.Modal {
  position: fixed;
  z-index: 200;
  border-radius: 12px;
  background-color: rgb(243, 245, 246);
  text-align: center;
  width: 38vw;
  height: 85vh;
  top: 10vh;
  left: 31vw;
  transition: all 1s ease-out;
}

.ModalOpen {
  opacity: 1;
  transform: translateY(0);
}

.ModalClosed {
  opacity: 0;
  transform: translateY(-150%);
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel img {
  height: 40vh !important;
  border-radius: 12px !important;
}

.projectTitle {
  font-size: 1.25rem !important;
  font-weight: 900;
}

.projectTechnology {
  position: relative;
  font-weight: 600;
  font-size: 1rem !important;
  color: #0275d8;
}

.projectDesc {
  position: relative;
  font-weight: 600;
  font-size: 0.85rem !important;
}

.descriptionContainer {
  position: relative;
  left: 5% !important;
  width: 90%;
}

.btnContainer {
  position: absolute;
  bottom: 5vh;
  width: 95%;
  left: 2.5%;
  text-align: center;
}

.btnStyle {
  margin: 0 0.25vw;
  font-size: 0.7rem;
  padding: 5px 15px;
}

.closeButton {
  position: absolute;
  bottom: 1vh;
  right: 1vw;
}

.closeButton i {
  font-size: 1.15rem;
  cursor: pointer;
}

.closeButton i:hover {
  color: #0275d8;
}

.inverse {
  background-color: #292b2c !important;
  border: 0.5px solid #292b2c !important;
}

.inverse:hover {
  background-color: #161616 !important;
  border: 0.5px solid #161616 !important;
}

.primary {
  background-color: #0275d8 !important;
  border: 0.5px solid #0275d8 !important;
}

.primary:hover {
  background-color: #0088ff !important;
  border: 0.5px solid #0088ff !important;
}

.success,
.success:hover {
  background-color: #5cb85c !important;
  border: 0.5px solid #5cb85c !important;
}

.danger {
  background-color: #d9534f !important;
  border: 0.5px solid #d9534f !important;
}

.danger:hover {
  background-color: #ff5f59 !important;
  border: 0.5px solid #ff5f59 !important;
}

@media screen and (min-width: 1800px) {
  .Modal {
    width: 28vw;
    height: 60vh;
    top: 15vh;
    left: 36vw;
  }

  .carousel img {
    height: 30vh !important;
    object-fit: cover !important;
  }

  .projectTitle {
    margin-top: 5vh;
  }
}

@media screen and (min-width: 1439px) {
  .carousel img {
    height: 380px;
    border-radius: 0;
    object-fit: fill;
  }
}

@media screen and (min-height: 900px) {
  .Modal {
    height: 80vh;
    top: 10vh;
  }

  .carousel img {
    object-fit: cover !important;
  }
}

@media screen and (max-width: 1025px) {
  .Modal {
    width: 100vw;
    height: 100vh;
    top: 0vh;
    left: 0vw;
  }

  .carousel {
    position: relative;
    width: 100%;
    top: 10vh !important;
  }

  .carousel img {
    height: 40vh !important;
    border-radius: 12px !important;
    object-fit: cover !important;
  }

  .projectTitle {
    margin-top: 15vh;
  }

  .descriptionContainer {
    position: relative;
    left: 20% !important;
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .carousel {
    margin-top: 1vh !important;
  }

  .carousel img {
    height: 30vh !important;
    object-fit: fill !important;
  }

  .projectTitle {
    margin-top: 12vh;
  }

  .projectTechnology {
    position: relative;
    font-weight: 600;
    font-size: 1rem !important;
  }

  .descriptionContainer {
    position: relative;
    left: 2.5% !important;
    width: 95%;
  }

  .btnContainer {
    width: 95%;
    left: 2%;
  }

  .btnStyle {
    margin: 0.25vh 1vw;
    font-size: 0.7rem;
    padding: 4px 15px;
  }

  .closeButton {
    font-size: 1.25rem !important;
    bottom: 1vh;
    right: 1vh;
  }
}

@media screen and (max-width: 321px) {
  .carousel {
    margin-top: 8vh;
  }

  .carousel img {
    height: 35vh !important;
    object-fit: fill !important;
  }

  .projectTitle {
    margin-top: 12vh;
    font-size: 0.95rem !important;
  }

  .projectTechnology {
    position: relative;
    font-weight: 600;
    font-size: 0.65rem !important;
  }

  .descriptionContainer p {
    font-size: 0.65rem !important;
  }

  .btnContainer {
    width: 95%;
    left: 2%;
  }

  .btnStyle {
    margin: 0.25vh 1vw;
    font-size: 0.5rem;
    padding: 4px 15px;
  }
}
