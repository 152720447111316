.Title {
  font-weight: 1000;
  margin-bottom: 0vh;
  font-size: 2rem;
}

.subTitle {
  margin: 2.5vh auto;
  text-align: center;
  font-weight: 800;
  font-size: 1.35rem;
}

.navTabs {
  margin-top: -5%;
}

.projectListing {
  display: grid;
  grid-gap: 0.7rem;
  grid-template-columns: repeat(3, 1fr);
}

.item {
  background-color: white;
  cursor: pointer;
}

.cardImg {
  height: 250px;
  object-fit: cover;
}

.item:hover img {
  position: relative;
  opacity: 0.1;
  transition: all 0.25s ease-out;
  z-index: 1;
}

.item:hover .togglePortion {
  visibility: visible;
}

.buttonStyle {
  position: relative;
  height: 30px;
  width: 150px;
  padding-bottom: 1px;
  padding-top: 2px;
  font-size: 0.85rem;
  margin-top: 50px;
  background-color: #0275d8 !important;
  border: 1px #0275d8 solid !important;
}

.togglePortion {
  position: relative;
  margin-bottom: 12.5%;
  z-index: 10;
  visibility: hidden;
}

.projectName {
  position: relative;
  margin-top: -50%;
  font-weight: 800;
  color: #0275d8;
  font-size: 1.15rem;
}

.techText {
  color: rgb(64, 64, 64);
  font-weight: 1000;
  font-size: 0.85rem;
}

.animation {
  z-index: 200;
}

@media screen and (min-height: 1000px) {
  .projectName {
    margin-top: -50% !important;
    font-size: 0.85rem;
  }

  .techText {
    font-size: 0.65rem !important;
  }

  .buttonStyle {
    height: 23.5px;
    width: 120px;
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 1200px) {
  .projectListing {
    grid-template-columns: repeat(2, 1fr);
  }

  .cardImg {
    width: 500px;
  }

  .projectName {
    margin-top: -40%;
  }
}

@media screen and (max-width: 1025px) {
  .buttonStyle {
    height: 23.5px;
    width: 120px;
    font-size: 0.6rem;
    margin-top: 22.5px;
  }

  .togglePortion {
    margin-bottom: 18%;
  }

  .projectName {
    margin-top: -40% !important;
  }

  .techText {
    font-size: 0.95rem;
  }

  .cardImg {
    height: 200px;
    width: 500px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1000px) {
  .cardImg {
    height: 250px;
  }

  .projectName {
    margin-top: -50% !important;
  }
}

@media screen and (max-width: 767px) {
  .buttonStyle {
    margin-top: 22.5px;
  }

  .subTitle {
    margin: 2.5vh auto;
    font-size: 1rem;
  }

  .togglePortion {
    margin-bottom: 14.5%;
  }

  .projectName {
    font-size: 1rem;
    margin-top: -47% !important;
  }

  .techText {
    font-size: 0.75rem;
  }

  .projectListing {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardImg {
    height: 250px;
    width: 500px;
    object-fit: cover;
  }
}

@media screen and (max-width: 321px) {
  .togglePortion {
    margin-bottom: 16%;
  }

  .projectName {
    margin-top: -58%;
  }
  .techText {
    font-size: 0.75rem;
  }
}
