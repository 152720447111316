.aboutMe {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: "bioimage bio bio";
  grid-template-columns: repeat(3, 1fr);
}

.aboutMeImage {
  grid-area: bioimage;
  object-fit: cover;
  min-zoom: 20;
}

.aboutMeText {
  grid-area: bio;
}

.aboutMeText p {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
}

.Title {
  margin-bottom: 7.5%;
  font-size: 2rem;
}

.background {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.span {
  color: rgb(2, 117, 216);
  font-weight: 600;
}

@media screen and (max-width: 1050px) {
  .aboutMeText {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .aboutMeText {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 767px) {
  .aboutMe {
    grid-template-areas: "bio" "bioText";
    grid-template-columns: repeat(1, 1fr);
  }

  .aboutMeImage {
    grid-area: bio;
  }

  .aboutMeText {
    grid-area: bioText;
    font-size: 0.8rem;
    text-align: center;
  }

  .aboutMeText p {
    font-size: 1rem;
  }

  .Title {
    margin-bottom: 12.5%;
  }
}
