@media screen and (max-width: 767px) {
  .viewResumeButton {
    position: relative;
  }

  .burgerButton {
    position: absolute;
    right: 25vw;
  }

  .viewResume {
    margin-left: -3.5vw !important;
  }
}
